@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

:root {
  --real-vh: 100vh;
  @include mat.form-field-overrides((
    filled-input-text-placeholder-color: rgba(0, 0, 0, 0.3),
    outlined-input-text-placeholder-color: rgba(0, 0, 0, 0.3),
  ));
}

$admin-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$magenta-palette,
    tertiary: mat.$orange-palette
  )
));

$shop-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$cyan-palette,
    tertiary: mat.$orange-palette
  )
));

html {
  @include mat.all-component-themes($shop-theme);
}

@mixin apply-theme($theme){
  //@include mat.button-theme($theme);
  mat-drawer-content {
    // background-color: mat.get-theme-color($theme, on-primary);
  }

  mat-toolbar {
    background-color: mat.get-theme-color($theme, primary);
    * {
      color: mat.get-theme-color($theme, on-primary);
    }
  }
  mat-sidenav {
    //background-color: mat.get-theme-color($theme, primary);
    * {
      color: mat.get-theme-color($theme, on-primary);
    }
  }
  .mat-background-primary {
    background-color: mat.get-theme-color($theme, primary);
  }
  .mat-color-on-primary {
    color: mat.get-theme-color($theme, primary);
  }
  .button-primary {
    background-color: mat.get-theme-color($theme, primary);
    color: mat.get-theme-color($theme, on-primary);;
  }
  .button-secondary {
    background-color: mat.get-theme-color($theme, secondary);
    color: mat.get-theme-color($theme, on-secondary);;
  }
  .button-tertiary {
    background-color: mat.get-theme-color($theme, tertiary);
    color: mat.get-theme-color($theme, on-tertiary);;
  }

}

html.admin-theme {
  @include apply-theme($admin-theme)
}

html.shop-theme {
  @include apply-theme($shop-theme)
}

html, body {
  height: calc(var(--real-vh));
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

h1, h2, h3, h4{
  margin: 0;
  font-weight: normal;
}

p {
  font-size: 0.8rem;
}

@media screen and (orientation:landscape){
  body {
    background-position: center center
  }
}

@media screen and (orientation:portrait){
  body {
    background-position: center top;
  }
}

.warn {
  color: red;
}

.no-margin{
  margin: 0 !important;
}

@media (max-width: 667px) {
  .view-port {
    display: flex;
    flex-direction: column;
    height: calc(var(--real-vh) + 56px);
  }

  .main-view-port {
    display: flex;
    flex-direction: column;
    height: calc(var(--real-vh) - 56px);
    overflow: auto;
  }
}

@media (min-width: 668px) {
  @media (max-height: 599px) {
    .view-port {
      display: flex;
      flex-direction: column;
      height: calc(var(--real-vh) + 64px);
      overflow: auto;
    }
  }

  @media (min-height: 600px){
    .view-port {
      display: flex;
      flex-direction: column;
      height: calc(var(--real-vh));
      overflow: auto;
    }
  }

  .main-view-port {
    display: flex;
    flex-direction: column;
    height: calc(var(--real-vh) - 64px);;
    overflow: auto;
  }

}

.maxWidthMedium {
  max-width: 664px;
  margin-left: auto;
  margin-right: auto;
}

.full-screen-dialog{
  --mdc-dialog-container-shape: 0px !important;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.overflow-hidden{
  overflow: hidden;
}

.no-scroll {
  overflow-y: clip !important;
}

.scrollable {
  overflow-y: scroll !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* containers */
.column-container {
  display: flex;
  flex-direction: column;
}

.column-container > * {
  width: 100%;
}

.row-container {
  display: flex;
  flex-wrap: wrap;
}

.column-width-equal{
  flex: 1 1 0;
  min-width: 0 !important;
}


.space-between {
  justify-content: space-between;
}

.align-center {
  display: flex;
  align-items: center;
}

.vertical-center {
  display: flex;
  align-items: center;
  width: 100%;
}

.vertical-center p, .vertical-center h1, .vertical-center h2, .vertical-center h3, .vertical-center h4{
  margin: 0
}

.horizontal-center{
  display: flex;
  justify-content: center;
}

.align-center p {
  margin: 0; /* enlever les marges */
  align-self: center; /* aligner le p verticalement au milieu */
}

.child-grow > * {
  flex-grow: 1;
}

.col {
  flex: 1 1;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}


.gap10px, .gapMd {
  gap: 10px;
}

.gapSm {
  gap: 5px;
}

.gap8px {
  gap: 8px;
}

.gap4px {
  gap: 4px
}
/* grid */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.grid-item {
  background-color: #ffffff;
}

.grid-item-1 {
  grid-column: 1 / 2;
}

.grid-item-2 {
  grid-column: 2 / 3;
}

.spacer{
  flex: 1 1 auto;
}

.mdc-button{
  font-weight: normal !important;
}

.searchBar{
  top: 0;
  position: sticky !important;
  z-index: 5;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 2px;
  padding-right: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 30);
}

/* searchBar Border */
.searchBar .mdc-notched-outline__leading {
 border: 0 !important;
}
.searchBar .mdc-notched-outline__notch {
  border:0  !important;
}
.searchBar .mdc-notched-outline__trailing {
  border: 0 !important;
}

/* time-picker Border*/
.timePickerFromSelector .mdc-notched-outline__leading {
  border: 0 !important;
}
.timePickerFromSelector .mdc-notched-outline__notch {
  border: 0  !important;
}
.timePickerFromSelector .mdc-notched-outline__trailing {
  border: 0 !important;
}

/* rent cards */
.infos p{
  color: darkgrey;
}

.rent-status-button[disabled], .chip[disabled] {
  background-image: linear-gradient(45deg, #ffffff 1.61%, rgba(0, 0,0,0) 1.61%, rgba(0, 0,0,0) 50%, #ffffff 50%, #ffffff 51.61%, rgba(0, 0,0,0) 51.61%, rgba(0, 0,0,0) 100%);
  background-size: 5px 5px;
}

/** rent-status-button color **/

.rent-status-button.waiting {
  background: gray !important;
  color: white !important;
}

.rent-status-button.called {
  background: #FFD700 !important; // Jaune
  color: black !important;
}

.chip.ready, .material-status-button.ready {
  background-color: #FFA500 !important;
  color: white !important;
}

.material-options.ready {
  background-color: rgba(255, 165, 0, 0.3) !important;
}

.chip.boarding, .material-status-button.boarding, .rent-status-button.boarding{
  background-color: #32CD32 !important;
  color: white !important;
}

.material-options.boarding{
  background-color: rgba(50, 205, 50, 0.3) !important;
}

.chip.sailing, .material-status-button.sailing, .rent-status-button.sailing{
  background-color: #1E90FF !important;
  color: white !important;

}

.material-options.sailing {
  background-color: rgba(30, 144, 255, 0.3) !important;
}


.chip.landing, .material-status-button.landing, .rent-status-button.landing{
   background-color: #9370DB !important;
   color: white !important;
 }

.material-options.landing{
  background-color: rgba(147, 112, 219, 0.3) !important;
}


.chip.ended{
  background-color: darkgray !important;
  color:white !important;
  span {
    text-decoration: line-through;
  }
}

.chip.unavailable, .material-status-button.unavailable{
  background-color: red !important;
  color: white !important;
}

/** rent-status-button Text **/

.rent-status-button.waiting::after{
  content: "APPELER" !important;
}

.rent-status-button.called::before{
  content: "APPEL EN COURS ";
}

.rent-status-button.boarding::after{
  content: "LANCER LOCATION"
}

.rent-status-button.sailing::after{
  content: "TERMINER LOCATION" !important;
}

.rent-status-button.landing::after{
  content: "CHECK OUT"
}



/** Material status button **/

.material-status-button.unavailable::after{
  content: "INDISPONIBLE"
}

.material-status-button.ready::after {
  content: "PRET"
}

.material-status-button.boarding::after{
  content: "AU DEPART"
}

.material-status-button.sailing::after {
  content:"EN COURS"
}

.material-status-button.landing::after{
  content: "RETOUR"
}


.chip {
  border-radius: 40px !important;
  border: 0;
}

.chip.switch {
  background-color: transparent;
}

.chip.no-shadows {
  box-shadow: none !important;
}

.chip.ready:disabled, .material-chip.boarding:disabled, .material-chip.sailing:disabled, .material-chip.landing:disabled{
  color: white;
}

/** SnackBar **/

.dark-snackbar {
  white-space: pre-wrap;
  background: black;
  color: white;
}

.dark-snackbar button {
  background: black;
  color: white;
  border: none;
}

.warn-snackbar {
  white-space: pre-wrap;
  background: red;
  color:white;
}

.warn-snackbar button {
  background: red;
  color:white;
  border: none;
}

mat-card {
  padding: 10px;
}

/* alignment titre card et button */
.card-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* unselectable => for hold button purpose */
.unselectable{
  user-select: none;
}
